export type MentionMeLandingParams = {
  /** if not provided, will try to load from URL query params, else will default to “landingpage” */
  situation?: string | undefined | "landingpage"
  /** if not provided, will default to “en_GB” */
  locale?: "en_GB" | undefined
  /** The customer's firstname, please URL encode. e.g. Brooklyn */
  firstname?: string | undefined | null
  /** The customer's surname, please URL encode. e.g. Earl */
  surname?: string | undefined | null
  /** The customer's full name, please URL encode. Brooklyn Earl */
  fullname?: string | undefined | null
  /** The customer's email address, please URL encode. e.g. brooklynearl799@mention-me.com */
  email?: string | undefined | null
  /** The unique customer identifier from your system MI7534466347 */
  customer_id?: string | undefined | null
  /** The customer's username. Your account is set up for referrers to share using their username not full name.
   * Please URL encode. */
  username?: string | undefined | null
  /** The customer's phone number(s). Pass as many phone numbers as you have as a comma separated list.
   * If you are passing any data which is not a digit (e.g. spaces, +'s), these need to be
   * URL encoded. +447529083676,+447966640964 */
  phone_number?: string | undefined | null
  /** Any piece of custom data you wish to pass to us */
  custom_field?: string | undefined | null
  /** A piece of text you can use to pass into sharing widgets, such as what the user bought or how much
   * they saved Blue Suede Shoes */
  custom_share_field?: string | undefined | null
  /** A URL to send users to after they are rewarded. Only functions on the embedded flow. */
  calltoactionurl?: string | undefined | null
  /** String representing a customer segment (for example one of: men, women) used to pass segmentation data
   * about your customers to us. Has a maximum number of 50 characters. */
  segment?: string | undefined | null
  /** Optionally override the way the flow is implemented (one of: link, form, embed). Default can also be set in the
   * merchant dashboard. If in doubt leave out. */
  implementation?: "link" | "form" | "embed" | undefined
};

export function loadMentionMeLanding(urlParams: MentionMeLandingParams): () => void {
  // eslint-disable-next-line no-param-reassign
  urlParams.situation = new URLSearchParams(window.location.search).get("situation") || urlParams.situation || "landingpage";
  // eslint-disable-next-line no-param-reassign
  urlParams.implementation = urlParams.implementation || "embed";
  return loadMentionMe("referreroffer", urlParams as MentionMeParams);
}

export type MentionMeDashboardParams = {
  /** if not provided, will try to load from URL query params, else will default to “dashboard” */
  situation?: string | undefined | "dashboard"
  /** if not provided, will default to “en_GB” */
  locale?: "en_GB" | undefined
  /** The customer's email address, please URL encode. e.g. brooklynearl799@mention-me.com */
  email: string
  /** The customer's firstname, please URL encode. e.g. Brooklyn */
  firstname: string
  /** The customer's surname, please URL encode. e.g. Earl */
  surname: string
  /** The customer's full name, please URL encode. Brooklyn Earl */
  fullname?: string | undefined
  /** The unique customer identifier from your system MI7534466347 */
  customer_id: string
  /** The customer's phone number(s). Pass as many phone numbers as you have as a comma separated list.
   *  If you are passing any data which is not a digit (e.g. spaces, +'s), these need to be
   *  URL encoded. +447529083676,+447966640964 */
  phone_number?: string | undefined
  /** Any piece of custom data you wish to pass to us */
  custom_field?: string | undefined
  /** Optional SHA256 hash of the customer's email concatenated with the merchant secret key which can be
   * used to authenticate this request if you require */
  key?: string | undefined
  /** Optionally override the way the flow is implemented (one of: link, form, embed). Default can also be
   * set in the merchant dashboard. If in doubt leave out. popup */
  implementation?: "popup" | "embed" | undefined
  /** String representing a customer segment (for example one of: men, women) used to pass segmentation
   * data about your customers to us. Has a maximum number of 50 characters. */
  segment?: string | undefined
};

export function loadMentionMeDashboard(urlParams: MentionMeDashboardParams): () => void {
  // eslint-disable-next-line no-param-reassign
  urlParams.situation = new URLSearchParams(window.location.search).get("situation") || urlParams.situation || "dashboard";
  // eslint-disable-next-line no-param-reassign
  urlParams.implementation = urlParams.implementation || "embed";
  return loadMentionMe("dashboard", urlParams as MentionMeParams);
}

export type MentionMeReferrerofferParams = {
  /** if not provided, will try to load from URL query params, else will default to “postpurchase” */
  situation?: string | undefined | "postpurchase"
  /** if not provided, will default to “en_GB” */
  locale?: "en_GB" | undefined
  /** The customer's firstname, please URL encode. e.g. Brooklyn */
  firstname: string
  /** The customer's surname, please URL encode. e.g. Earl */
  surname: string
  /** The customer's email address, please URL encode. e.g. brooklynearl799@mention-me.com */
  email: string
  /** The unique order identifier from your system. Has a maximum number of 50 characters. e.g. 5534466347 */
  order_number: number
  /** The order subtotal (excluding VAT/taxes and shipping) in the currency indicated by the order_currency parameter.
   * If you can't remove VAT, let us know e.g. 100.75 */
  order_subtotal: string
  /** The three character (ISO 4217) currency code that the order total is in e.g. GBP */
  order_currency: "GBP"
  /** The date and time the order was placed. The format of the date must be in ISO 8601 format and URL encoded.
   * If not provided or invalid, we will use the date and time at the point we received
   * the tag. 2022-09-29T15:45:47+0000 */
  order_date: string
  /** The unique customer identifier from your system MI7534466347 */
  customer_id: string
  /** The coupon code used by the customer (if any) ABC5550 */
  coupon_code?: string | undefined
  /** The customer's phone number(s). Pass as many phone numbers as you have as a comma separated list.
   * If you are passing any data which is not a digit (e.g. spaces, +'s), these need to be URL encoded.
   * +447529083676,+447966640964 */
  phone_number: string | undefined
  /** The customer's title. This allows you to have more formal messaging in your flow.
   * Has a maximum number of 20 characters. */
  title?: string | undefined
  /** The customer's full name, please URL encode. Brooklyn Earl */
  fullname?: string | undefined
  /** The customer's username. Your account is set up for referrers to share using their username not full name.
   * Please URL encode. */
  username?: string | undefined
  /** Any piece of custom data you wish to pass to us */
  custom_field?: string | undefined
  /** The customer's address line 1 (if known) Flat 1 */
  address_line1: string | undefined
  /** The customer's address line 2 (if known) 10 Smith Street */
  address_line2?: string | undefined
  /** The customer's address city (if known) London */
  address_city: string | undefined
  /** The customer's address county (if known) */
  address_county: string | undefined
  /** The customer's address postcode (if known) NE14 5BX */
  address_postcode: string | undefined
  /** The customer's address country (if known) UK */
  address_country: "United Kingdom"
  /** A piece of text you can use to pass into sharing widgets, such as what the user bought or how much they
   * saved Blue Suede Shoes */
  custom_share_field?: string | undefined
  /** In some cases it may be desirable to ensure that email addresses and other information passed in promotional
   * links is not in clear (or plain) text. If you wish to use this parameter then please contact us as a shared
   * encryption key will need to be setup and further testing is required to ensure we can correctly decode this
   * parameter. */
  mm_e?: string | undefined
  /** Optionally override the way the flow is implemented (one of: link, form, embed). Default can also be set in
   * the merchant dashboard. If in doubt leave out. popup */
  implementation?: "popup" | "link" | undefined
  /** String representing a customer segment (for example one of: men, women) used to pass segmentation data about
   * your customers to us. Has a maximum number of 50 characters. */
  segment?: string | undefined
  /** Integer representing the index of an offer within an experiment. Used to override the dynamic allocation of
   * offers to customers so that, for example, email creative can be set up with a specific to link to show a
   * specific variation. Default is null. */
  variation?: number | undefined
};

export function loadMentionMeReferreroffer(urlParams: MentionMeReferrerofferParams): () => void {
  // eslint-disable-next-line no-param-reassign
  urlParams.situation = new URLSearchParams(window.location.search).get("situation") || urlParams.situation || "postpurchase";
  // eslint-disable-next-line no-param-reassign
  urlParams.implementation = urlParams.implementation || "popup";
  return loadMentionMe("referreroffer", urlParams as MentionMeParams);
}

export type MentionMeRefereefindParams = {
  /** if not provided, will try to load from URL query params, else will default to “subscriptioncheckout” */
  situation?: string | undefined | "checkout"
  /** if not provided, will default to “en_GB” */
  locale?: "en_GB" | undefined
  /** The customer's firstname, please URL encode. e.g. Payton */
  firstname: string
  /** The customer's surname, please URL encode. e.g. Timpson */
  surname?: string | undefined
  /** The customer's full name, please URL encode. e.g. Payton Timpson */
  fullname: string
  /** The customer's email address, please URL encode. e.g. paytontimpson498@mention-me.com */
  email: string
  /** The unique customer identifier from your system. e.g. MI1334353703 */
  customer_id: string
  /** The customer's phone number(s). Pass as many phone numbers as you have as a comma separated list. If you are
   * passing any data which is not a digit (e.g. spaces, +'s), these need to be URL encoded.
   * e.g. +447892143139,+447072102866 */
  phone_number?: string | undefined
  /** A URL to send users to after they are rewarded. Only functions on the embedded flow. */
  calltoactionurl?: string | undefined
  /** String representing a customer segment (for example one of: men, women) used to pass segmentation data about
   * your customers to us. Has a maximum number of 50 characters. */
  segment?: string | undefined
  /** Optionally override the way the flow is implemented (one of: link, form, embed). Default can also be set in
   * the merchant dashboard. If in doubt leave out. */
  implementation?: "link" | "form" | "embed" | undefined
};

export function loadMentionMeRefereefind(urlParams: MentionMeRefereefindParams): () => void {
  // eslint-disable-next-line no-param-reassign
  urlParams.situation = new URLSearchParams(window.location.search).get("situation") || urlParams.situation || "checkout";
  // eslint-disable-next-line no-param-reassign
  urlParams.implementation = urlParams.implementation || "link";
  return loadMentionMe("refereefind", urlParams as MentionMeParams);
}

type MentionMeScript = "refereefind" | "referreroffer" | "dashboard";
type MentionMeParams = {
  [key: string]: string | number
} & {
  locale?: "en_GB" | undefined
  situation: "landingpage" | "dashboard" | "postpurchase" | "checkout" | string
  implementation: "link" | "form" | "embed" | "popup" | string
};

function loadMentionMe(script: MentionMeScript, urlParams: MentionMeParams): () => void {
  const id = `${script}-loader-id`;
  if (!document.getElementById(id)) {
    // eslint-disable-next-line no-param-reassign
    if (!urlParams.locale) urlParams.locale = "en_GB";
    // const isStaging = !document.location.hostname.endsWith("www.katkin.com")
    // || new URL(`${document.location}`).searchParams.get("mentionMeTest") === "true";
    // const urlHost = "tag-demo.mention-me.com";
    const urlHost = "tag.mention-me.com";
    const urlQuery = Object.entries(urlParams)
      .filter(([ key, value ]) => key && value && `${value}`.trim())
      .map(([ key, value ]) => `${key}=${encodeURIComponent(`${value}`)}`)
      .join("&");
    // eslint-disable-next-line no-console
    console.log("Loading mentionMe with [script=", script, ", urlHost=", urlHost, ", urlQuery=", urlQuery, ", urlParams=", urlParams, "]");

    const js = document.createElement("script");
    js.id = id;
    js.type = "text/javascript";
    js.defer = true;
    js.src = `https://${urlHost}/api/v2/${script}/mm61ae86f8${urlQuery ? `?${urlQuery}` : ""}`;
    document.body.append(js);
  }

  return () => {
    document.getElementById(id)?.remove();
    const mmWrapper = document.getElementById("mmWrapper");
    if (mmWrapper) mmWrapper.innerHTML = "";
    if (window.MentionMeFiredTags) {
      const tagName = urlParams.implementation + urlParams.situation;
      // eslint-disable-next-line no-console
      console.log("delete MM tag", tagName);
      delete window.MentionMeFiredTags[tagName];
    }
  };
}

declare global {
  interface Window {
    MentionMeFiredTags?: Record<string, boolean>;
  }
}
