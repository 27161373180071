import { ApiClientsContext } from "apps/website/contexts/api";

import { TrackerContext } from "../types";

export type Trigger = PostCheckoutAuthSignInTrigger
| PostCheckoutGTMTrigger
| PostCheckoutMentionMeTrigger
| KlaviyoTrackPageVisitedTrigger;

export enum TriggerName {
  POST_CHECKOUT_GTM = "post_checkout_gtm",
  POST_CHECKOUT_MENTION_ME = "post_checkout_mentionme",
  POST_CHECKOUT_AUTH_SIGN_IN = "post_checkout_auth_sign_in",
  KLAVIYO_TRACK_PAGE_VISITED = "klaviyo_track_page_visited",
  WONDERING_TRIGGER = "wondering_trigger",
}

interface IBaseTrigger<T extends TriggerName> {
  component: T;
}

export type PostCheckoutGTMTrigger = IBaseTrigger<TriggerName.POST_CHECKOUT_GTM>;

export interface PostCheckoutMentionMeTrigger extends IBaseTrigger<TriggerName.POST_CHECKOUT_MENTION_ME> {
  enablePopup?: boolean;
}

export type PostCheckoutAuthSignInTrigger = IBaseTrigger<TriggerName.POST_CHECKOUT_AUTH_SIGN_IN>;

export type KlaviyoTrackPageVisitedTrigger = IBaseTrigger<TriggerName.KLAVIYO_TRACK_PAGE_VISITED>;

export type WonderingTrigger = IBaseTrigger<TriggerName.WONDERING_TRIGGER> & {
  name: string
};

// A trigger handler returns true or false based on if
// the action actually ran or not.
export type TriggerHandler<T extends Trigger> = (
  api: ApiClientsContext,
  context: TrackerContext,
  trigger: T,
) => boolean | Promise<boolean>;

type ExtractTriggerFromTriggerName<T extends TriggerName> = Extract<Trigger, { component: T }>;

export type TriggerHandlerMap = {
  [k in Trigger["component"]]: TriggerHandler<ExtractTriggerFromTriggerName<k>>;
};
