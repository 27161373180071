import { useCallback, useState } from "react";

export type Toggler = <T>(_?: T | boolean) => void;

// Helper hook for true/false states
export function useToggle(init = false): [ boolean, Toggler ] {
  const [ value, setValue ] = useState(init);

  const toggle = useCallback(<T>(newValue?: T | boolean) => {
    if (typeof newValue === "boolean") {
      setValue(newValue);
    } else {
      setValue((old) => !old);
    }
  }, []);

  return [ value, toggle ];
}
