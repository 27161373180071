import { useState } from "react";

import { useUpdateEffect } from "./useUpdateEffect";

// Like `useState`, but will also update the value
// whenever the input changes.
export function useUpdatedState<T>(updatableValue: T) {

  const [ state, setState ] = useState(updatableValue);

  useUpdateEffect(() => {
    setState(updatableValue);
  }, [ updatableValue ]);

  return [ state, setState ] as const;
}
