"use client";

import { FC, useEffect, useRef } from "react";
import { useRouter } from "next/navigation";
import { usePostHog } from "posthog-js/react";

import { IStoryBlokPageExperimentRedirect } from "../components/page/api.types";
import { useQueryParams } from "../hooks/useQueryParams";

interface Props {
  redirects?: IStoryBlokPageExperimentRedirect[];
}

export const ExperimentRedirects: FC<Props> = ({
  redirects,
}) => {
  const { chainQueryParamsToUrl } = useQueryParams();

  const posthog = usePostHog();

  const router = useRouter();

  /** We assume only 1 redirect experiment is run on any page at one time,
    * so 1 true/false value is enough
    */
  const eventHasFired = useRef(false);

  useEffect(() => {

    if (!redirects || !Array.isArray(redirects)) return;

    posthog.onFeatureFlags(() => {
      for (const redirect of redirects) {

        /** i.e. "control" or "test" */
        const featureFlagResult = posthog.getFeatureFlag(redirect.experiment);

        if (!eventHasFired.current) {
          posthog.capture("redirect_exp_begin", {
            experiment: redirect.experiment,
            group: featureFlagResult,
          });

          eventHasFired.current = true;
        }

        if (featureFlagResult === redirect.variant) {

          void router.push(chainQueryParamsToUrl(redirect.redirect_to));
        }
      }
    });
  }, [ redirects, posthog, router, chainQueryParamsToUrl ]);
  return (<></>);
};
