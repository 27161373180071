import {
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import debounce from "lodash.debounce";

import { useUpdateEffect } from "./useUpdateEffect";

type SearchFunction<T, F extends object>
  = (
    _: string,
    pageNumber: number,
    options?: Partial<F>,
    abortController?: AbortController
  ) => Promise<T[]>;

function nonEmptySearchValue(value: unknown) {
  // Empty arrays are not valid search values
  if (Array.isArray(value)) {
    return value.length > 0;
  }
  // Null and undefined are not valid search values
  if (value === undefined || value === null) {
    return false;
  }
  // If this is a blank string, it's not a valid search value
  if (typeof value === "string" && value.trim().length < 1) {
    return false;
  }
  // Else, this is a valid search value
  return true;

}

function isQueryEmpty(
  query: string,
  filters: object | undefined,
  mandatoryFilters: string[] | undefined,
) {
  // If the query is non-empty and there are no mandatory filters, then the query is non-empty
  if (query.trim().length > 0
    && (!mandatoryFilters || mandatoryFilters.length < 1)) {
    return false;
  }
  // Else, the query is empty or there are mandatory filters, so we need to check the filters
  let filtersToCheck = Object.entries(filters ?? {});
  // If there are mandatory filters, then we only need to check them and not every filter
  if (mandatoryFilters && mandatoryFilters.length > 1) {
    filtersToCheck = filtersToCheck.filter(([ key ]) => mandatoryFilters.includes(key));
  }
  // Check for non-empty filter values
  const nonEmptyFilters = filtersToCheck.filter(([ ,value ]) => nonEmptySearchValue(value));
  // If there are non-empty filter values, then the query is non-empty
  if (nonEmptyFilters.length > 0) {
    return false;
  }
  // Else, the query is empty
  return true;
}

export function useSearch<T, F extends object>(
  searchFn: SearchFunction<T, F>,
  initialPage = 1,
  initialQuery = "",
  initialResults: T[] = [],
  filters?: Partial<F>,
  allowEmptySearch = false,
  mandatoryFilters?: (keyof F & string)[],
) {

  const abortController = useRef(new AbortController());

  const [ query, setQuery ] = useState(initialQuery);
  const [ page, setPage ] = useState(initialPage);

  // Reference to the previous query to check what's changed
  const prevQuery = useRef(initialQuery);
  const prevFilters = useRef<Partial<F>>(filters ?? {});

  const [ loading, setLoading ] = useState(false);

  const [ results, setResults ] = useState<T[]>(initialResults);

  const onSubmit = useCallback(async (newQuery: string, pageNumber: number, fs?: Partial<F>) => {
    abortController.current.abort();
    if (isQueryEmpty(newQuery, fs, mandatoryFilters) && !allowEmptySearch) {
      setLoading(false);
      setResults([]);
      return;
    }
    setLoading(true);
    const res = await searchFn(
      newQuery,
      pageNumber,
      fs,
      abortController.current,
    );
    setResults(res);
    setLoading(false);
  }, [ searchFn, mandatoryFilters ]);

  const lookup = useCallback(debounce(onSubmit, 500), [ onSubmit ]);

  useUpdateEffect(() => {

    const emptyQuery = isQueryEmpty(query, filters, mandatoryFilters);

    if (emptyQuery && !allowEmptySearch) {
      abortController.current.abort();
      setLoading(false);
      setResults([]);
    } else if ((prevQuery.current !== query || prevFilters.current !== filters) && page !== 1) {
      // Query has changed, and page is not 1, so reset the page
      // and let the next effect trigger do the searching
      setPage(1);
    } else {
      // Otherwise, search regularly
      prevQuery.current = query;
      prevFilters.current = filters ?? {};
      setLoading(true);
      void lookup(query, page, filters);
    }

  }, [ query, page, filters, mandatoryFilters ]);

  // Set to true if the input is empty.
  // If this is false, that doesn't mean that a search will happen, since mandatory fields might not have been set.
  const noFiltersSet = useMemo(() => isQueryEmpty(query, filters, undefined), [ query, filters ]);

  // If some filters are set but no mandatory filters are set,
  // then set this to true, so that we can display a warning
  const mandatoryFiltersNotSet = useMemo(() => {
    if (!mandatoryFilters || mandatoryFilters.length < 1) {
      return false;
    }
    const isEmpty = isQueryEmpty(query, filters, undefined);
    if (isEmpty) {
      return false;
    }
    const mandatoryFieldsNotSet = isQueryEmpty(query, filters, mandatoryFilters);
    return mandatoryFieldsNotSet;
  }, [ query, filters, mandatoryFilters ]);

  return {
    query,
    setQuery,
    page,
    setPage,
    loading,
    results,
    onSubmit,
    noFiltersSet,
    mandatoryFiltersNotSet,
  } as const;

}
