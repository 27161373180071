import { TriggerHandlerMap, TriggerName } from "../types";

import { authSignInHandler } from "./auth";
import { gtmHandler } from "./gtm";
import { mentionMeHandler } from "./mentionme";
import { klaviyoTrackPageVisitedHandler } from "./klaviyo";

export const TRIGGER_HANDLERS: TriggerHandlerMap = {
  [TriggerName.POST_CHECKOUT_MENTION_ME]: mentionMeHandler,
  [TriggerName.POST_CHECKOUT_GTM]: gtmHandler,
  [TriggerName.POST_CHECKOUT_AUTH_SIGN_IN]: authSignInHandler,
  [TriggerName.KLAVIYO_TRACK_PAGE_VISITED]: klaviyoTrackPageVisitedHandler,
};
