import { ChangeEvent, useCallback, useState } from "react";

interface IUseTextInputOpts {
  capitalize?: boolean;
}

const DEFAULT_OPTIONS = {};

/**
 * Helper function for handling text inputs. Can also handle raw string changes.
 */
export function useTextInput(
  initialValue = "",
  options: IUseTextInputOpts = DEFAULT_OPTIONS,
) {
  const [ value, _setValue ] = useState(initialValue);

  const setValue = useCallback((s: string) => {
    if (options.capitalize) _setValue(s.toUpperCase());
    else _setValue(s);
  }, [ _setValue ]);

  const onChange = useCallback((
    eventOrString : string | ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => {
    if (typeof eventOrString === "string") setValue(eventOrString);
    else setValue(eventOrString.target.value);
  }, [ setValue ]);

  return [ value, onChange ] as const;
}
