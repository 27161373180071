import { Dispatch, SetStateAction } from "react";

import { ApiClientsContext } from "apps/website/contexts/api";

import { FetchedSources, TrackerContext } from "../types";

interface IBaseSource<T extends SourceName> {
  component: T;
}

export enum SourceName {
  GET_FIRST_ORDER = "get_first_order",
}

export interface GetFirstOrderSource extends IBaseSource<SourceName.GET_FIRST_ORDER> {
  flow?: string;
}

export type Source = GetFirstOrderSource;

export type SetFetchedSources = Dispatch<SetStateAction<FetchedSources>>;

export type SourceHandler<S extends Source> = (
  api: ApiClientsContext,
  context: TrackerContext,
  source: S,
  setter: SetFetchedSources
) => void | Promise<void>;

type ExtractSourceFromSourceName<T extends SourceName> = Extract<Source, { component: T }>;

export type SourceHandlerMap = {
  [k in Source["component"]]: SourceHandler<ExtractSourceFromSourceName<k>>;
};
