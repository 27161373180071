import { Source, SourceName } from "./sources";
import { Trigger, TriggerName } from "./triggers";

type IDefaultSources = {
  [k in SourceName]: Source;
};

// This object lists which data sources are required by each trigger
// If a source has not been explicitly listed on the storyblok page,
// then our business logic should pull in these sources automatically.
export const DEFAULT_SOURCES: IDefaultSources = {
  [SourceName.GET_FIRST_ORDER]: {
    component: SourceName.GET_FIRST_ORDER,
  },
};

type ITriggerSourceDependencies = {
  [k in TriggerName]: SourceName[];
};

// This object lists which data sources are required by each trigger
// If a source has not been explicitly listed on the storyblok page,
// then our business logic should pull in these sources automatically.
export const TRIGGER_SOURCE_DEPENDENCIES: ITriggerSourceDependencies = {
  [TriggerName.POST_CHECKOUT_GTM]: [ SourceName.GET_FIRST_ORDER ],
  [TriggerName.POST_CHECKOUT_AUTH_SIGN_IN]: [ SourceName.GET_FIRST_ORDER ],
  [TriggerName.POST_CHECKOUT_MENTION_ME]: [ SourceName.GET_FIRST_ORDER ],
  [TriggerName.KLAVIYO_TRACK_PAGE_VISITED]: [],
  [TriggerName.WONDERING_TRIGGER]: [],
};

/**
 * Given the provided triggers and data sources from storyblok, populate additional
 * sources that are required. e.g.
 * T1 requires S1 and S2
 * T2 requires S1 and S3
 * and S1 and S2 are explicitly provided
 * then we "autofill" an S3 using a default S3
 * and return [ S1, S2, S3 ]
 * @param triggers
 * @param providedSources
 */
export function computeRequiredDefaultSources(
  triggers: Trigger[] = [],
  providedSources: Source[] = [],
): Source[] {

  const requiredSourceNames = new Set(triggers.flatMap((t) => TRIGGER_SOURCE_DEPENDENCIES[t.component]));

  const providedSourceNames = providedSources.map((s) => s.component);

  const additionalSourceNamesRequired = [ ...requiredSourceNames ].filter((s) => !providedSourceNames.includes(s));

  const defaultSourcesRequired = additionalSourceNamesRequired.map((name) => DEFAULT_SOURCES[name]);

  return [ ...providedSources, ...defaultSourcesRequired ];
}
