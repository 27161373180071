import {
  loadMentionMeReferreroffer,
} from "apps/website/utils/mentionme/loadMentionMe";

import { PostCheckoutMentionMeTrigger, TriggerHandler } from "../types";

export const mentionMeHandler: TriggerHandler<PostCheckoutMentionMeTrigger> = (
  _,
  context,
  trigger,
) => {

  const data = context.sources?.firstOrder;

  if (!data) return false;

  // @ts-ignore
  const katkinCustomerId = context.query.get("katkinCustomerId");

  loadMentionMeReferreroffer({
    firstname: data.firstName,
    surname: data.lastName,
    email: data.charge.email,
    order_number: data.charge.id,
    order_subtotal: data.price_no_vat,
    order_currency: "GBP",
    order_date: data.charge.processed_at_instant || data.charge.created_at_instant,
    customer_id: katkinCustomerId as string,
    coupon_code: data.discountCode,
    phone_number: data.charge.shipping_address?.phone,
    fullname: data.fullName,
    address_line1: data.charge.shipping_address?.address1,
    address_line2: data.charge.shipping_address?.address2,
    address_city: data.charge.shipping_address?.city,
    address_county: data.charge.shipping_address?.province,
    address_postcode: data.charge.shipping_address?.zip,
    address_country: "United Kingdom",
    custom_share_field: data.catNames,
    custom_field: data.catNames,
    // We want to not show the mentionMe popup but still emit the MentionMe event
    // so that the use of a referral code is tracked. We change implementation to
    // link, since there is (usually) no mmWrapper tag in welcome pages meaning no
    // link will be inserted.
    implementation: trigger.enablePopup ? "popup" : "link",
  });

  return true;

};
