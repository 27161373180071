import { PostCheckoutAuthSignInTrigger, TriggerHandler } from "../types";

export const authSignInHandler: TriggerHandler<PostCheckoutAuthSignInTrigger> = async (
  _api,
  context,
  _trigger,
) => {

  const data = context.sources?.firstOrder;

  if (!data) return false;

  if (data.authData && data.authData.access_token && data.authData.refresh_token) {
    await context.auth.forceLogin(data.authData, true);
    return true;
  }

  return false;

};
