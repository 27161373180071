"use client";

import { useSearchParams } from "next/navigation";

interface IUserQueryParams {
  chainQueryParamsToUrl(url: string, params?: Record<string, string | undefined>): string;
  queryParamsForInterpolation(): Record<string, string>
}
export function useQueryParams(): IUserQueryParams {
  const query = useSearchParams();

  const chainQueryParamsToUrl = (url: string, params?: Record<string, string | undefined>) => {
    if (!query && !params) return url;
    const paramStrings: string[] = [];
    let paramString = "";
    const hash = typeof window !== "undefined" && window.location.hash ? `${window.location.hash}` : "";
    const hashLessUrl = url.replace(hash, "");
    const paramsPrefix = hashLessUrl.includes("?") ? "&" : "?";

    if (params) {
      Object.entries(params).forEach(([ key, value ]) => {
        if (!!value && key !== "slug" && !url.includes(`${key}=`)) {
          const paramKeyValue = `${key}=${value}`;
          if (!paramStrings.includes(paramKeyValue)) {
            paramStrings.push(`${key}=${value}`);
          }
        }
      });
      paramString = paramStrings.join("&");
    } else {
      paramString = hashLessUrl.includes(query.toString()) ? "" : query.toString();
    }

    if (!paramString.length) return hashLessUrl;

    return `${hashLessUrl}${paramsPrefix}${encodeURI(paramString)}${hash}`;
  };

  const queryParamsForInterpolation = () => {
    const queryValues: Record<string, string> = {};
    Object.entries(query).forEach(([ key, value ]) => {
      if (value) {
        queryValues[`$${key}`] = value?.toString();
      }
    });
    return queryValues;
  };

  return {
    chainQueryParamsToUrl,
    queryParamsForInterpolation,
  };
}
