import { ChangeEvent, useCallback, useState } from "react";

export function useIntegerInput(initialValue?: number) {

  const [ value, setValue ] = useState<number | undefined>(initialValue);

  const parseToNum = useCallback((s: string) => {

    const parsed = Number.parseInt(s.replace(/\D/, ""), 10);

    if (!Number.isNaN(parsed)) setValue(parsed);
  }, [ setValue ]);

  const onChange = useCallback((eventOrValue : number | string | ChangeEvent<HTMLInputElement>) => {

    if (typeof eventOrValue === "number") {
      setValue(eventOrValue);
      return;
    }

    if (eventOrValue === "") {
      setValue(undefined);
      return;
    }

    const eventString = (typeof eventOrValue === "string")
      ? eventOrValue
      : eventOrValue.target.value;

    parseToNum(eventString);

  }, [ setValue ]);

  return [ value, onChange ] as const;
}
