import Axios from "axios";

import { TrackerContext } from "../../types";
import { GetFirstOrderSource, SourceHandler } from "../types";

const sleep = (n: number) => new Promise((res) => {
  setTimeout(res, n);
});

export const getFirstOrderHandler: SourceHandler<GetFirstOrderSource> = async (
  api,
  context: TrackerContext,
  source,
  setter,
) => {

  if (context.sources.firstOrder !== undefined) return;

  // @ts-ignore
  const katkinCustomerId = context.query.get("katkinCustomerId");
  const flow = context.query.get("flow");

  if (!katkinCustomerId) {
    console.error("no katkinCustomerId provided. katkinCustomerId=", katkinCustomerId);
    return;
  }

  let retries = 10;

  while (retries > 0) {

    try {
      const res = await api.FreshMealPlan.getCustomerFirstOrder(
        katkinCustomerId as string,
        source.flow ?? flow as string,
      );

      setter((old) => ({ ...old, firstOrder: res.data }));
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        if (e?.response?.status === 200) {
          console.error(`axiosError: failed to get customer data for [katkinCustomerId=${katkinCustomerId}]`, e);
          return;
        }
        console.error(`axiosError: bad response when getting customer data for [katkinCustomerId=${katkinCustomerId}], retrying...`, e);

      } else {
        console.error(`failed to get customer data for [katkinCustomerId=${katkinCustomerId}]`, e);
        return;
      }
    }
    await sleep(2000);
    retries -= 1;
  }

};
