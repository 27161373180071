import { KlaviyoTrackPageVisitedTrigger, TriggerHandler } from "../types";

export const klaviyoTrackPageVisitedHandler: TriggerHandler<KlaviyoTrackPageVisitedTrigger> = async (
  api,
  context,
  _trigger,
) => {
  if (!context.auth.accessToken) return false;

  await api.Customer.visitedPage(window.location.href, context.auth.accessToken);
  return true;
};
