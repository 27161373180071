import { useCallback, useMemo, useState } from "react";

export function useList<T>(
  initialList: T[] = [],
  equals = (t1: T, t2: T) => t1 === t2,
) {

  const [ list, setList ] = useState(initialList);

  const add = useCallback((t: T) => setList((old) => [ ...old, t ]), [ setList ]);

  const remove = useCallback((t: T) => setList((old) => old.filter((v) => equals(v, t))), [ setList ]);

  const clear = useCallback(() => setList([]), [ setList ]);

  const isEmpty = useMemo(() => list.length === 0, [ list ]);

  return {
    list,
    add,
    remove,
    clear,
    isEmpty,
  };

}
