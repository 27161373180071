"use client";

import { FC } from "react";
import { setCookie } from "nookies";

import { PublicConfig } from "apps/website/config/public";

import { Cookie } from "./api.types";

interface ICookiesProps {
  cookies: Cookie[]
}

export const Cookies: FC<ICookiesProps> = ({ cookies }) => {
  cookies.forEach((cookie) => {
    setCookie(null, cookie.key, cookie.value, {
      domain: PublicConfig.NEXT_PUBLIC_COOKIE_DOMAIN,
      expires: cookie.expires ?? undefined,
    });
  });

  return (<></>);
};
